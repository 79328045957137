var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box" }, [
    _vm.showTitle && _vm.titleClass
      ? _c("label", { staticClass: "title" }, [
          _c("i", { staticClass: "type", class: _vm.titleClass }),
          _c("i", {
            staticClass: "more el-icon--homeMore",
            on: {
              click: function($event) {
                return _vm.jumpPage({
                  name: "list-ges",
                  params: { categoryId: _vm.categoryId, type: _vm.type }
                })
              }
            }
          })
        ])
      : _vm._e(),
    _c(
      "ul",
      { staticClass: "items" },
      _vm._l(_vm.data, function(item, key) {
        return _c("li", { key: key }, [
          item.avatar
            ? _c("img", {
                staticClass: "avatar",
                attrs: { src: item.avatar },
                on: {
                  click: function($event) {
                    return _vm.handleEmit({
                      name: "detail",
                      params: { id: item.id, type: _vm.type }
                    })
                  }
                }
              })
            : _c(
                "i",
                {
                  staticClass: "avatar",
                  on: {
                    click: function($event) {
                      return _vm.handleEmit({
                        name: "detail",
                        params: { id: item.id, type: _vm.type }
                      })
                    }
                  }
                },
                [_vm._v("Melinked\n      ")]
              ),
          _c("label", [
            _c("span", {
              staticClass: "name",
              domProps: {
                innerHTML: _vm._s(
                  item.subject.length > 54
                    ? item.subject.slice(0, 54) + "..."
                    : item.subject
                )
              },
              on: {
                click: function($event) {
                  return _vm.handleEmit({
                    name: "detail",
                    params: { id: item.id, type: _vm.type }
                  })
                }
              }
            }),
            _c("p", {
              staticClass: "desc",
              domProps: {
                innerHTML: _vm._s(
                  item.content.length > 70
                    ? item.content.slice(0, 70) + "..."
                    : item.content
                )
              },
              on: {
                click: function($event) {
                  return _vm.handleEmit({
                    name: "detail",
                    params: { id: item.id, type: _vm.type }
                  })
                }
              }
            })
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }