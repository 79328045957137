<template>
  <div class="box">
    <label class="title" v-if="showTitle && titleClass">
      <i class="type" :class="titleClass"></i>
      <i
        class="more el-icon--homeMore"
        @click="jumpPage({ name: 'list-ges', params: { categoryId, type } })"
      ></i>
    </label>
    <ul class="items">
      <li v-for="(item, key) in data" :key="key">
        <img
          class="avatar"
          v-if="item.avatar"
          :src="item.avatar"
          @click="handleEmit({ name: 'detail', params: { id: item.id, type } })"
        />
        <i
          v-else
          class="avatar"
          @click="handleEmit({ name: 'detail', params: { id: item.id, type } })"
          >Melinked
        </i>
        <label>
          <span
            class="name"
            v-html="
              item.subject.length > 54
                ? item.subject.slice(0, 54) + '...'
                : item.subject
            "
            @click="
              handleEmit({ name: 'detail', params: { id: item.id, type } })
            "
          ></span>
          <p
            class="desc"
            @click="
              handleEmit({ name: 'detail', params: { id: item.id, type } })
            "
            v-html="
              item.content.length > 70
                ? item.content.slice(0, 70) + '...'
                : item.content
            "
          ></p>
        </label>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "Recommended",
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      }
    },
    showTitle: {
      type: Boolean,
      default: false
    },
    titleClass: {
      type: String,
      default: ""
    },
    type: {
      type: Number,
      default: 0
    },
    categoryId: {
      type: String,
      default: ""
    }
  },
  methods: {
    handleEmit(router) {
      this.$emit("recommendEmit", router);
    }
  }
};
</script>
<style lang="stylus">
.box {
  background: #ffffff;
  padding: 15px;
  margin: 0.9rem 0 0;

  &:nth-child(1) {
    margin: 0;
  }

  .title {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;

    i {
      font-size: 1.5rem;

      &.type {
        color: #000000;
      }

      &.more {
        cursor: pointer;
        color: #c5c5c5;
        font-size: 2rem;

        &:hover {
          color: #999999;
        }
      }
    }
  }

  .items {
    display: flex;
    flex-flow: column;
    margin: 0.9rem 0 0;

    li {
      margin: 1rem 0 0;
      display: flex;
      flex-flow: row;

      &:hover {
        cursor: pointer;
        background: #fafafa;
      }

      .avatar {
        min-width: 5rem;
        width: 5rem;
        height: 4rem;
        object-fit: cover;
        margin-right: 0.5rem;
        border-radius: 3px;
        background: #eeeeee;
        display: block;
        font-style: normal;
        line-height: 4rem;
        text-align: center;
        color: #dddddd;
        font-weight: 800;
        font-size: 0.98rem;
      }

      label {
        flex: 1;
        overflow: hidden;
        max-width: 248px;

        .name {
          font-size: 15px;
          font-weight: 600;
        }

        .desc {
          font-size: 13px;
          color: #666;
          cursor: pointer;
          margin: 0.25rem 0;
        }
      }
    }
  }
}
</style>
