var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page detail" },
    [
      _c("div", { staticClass: "narrowBox" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _vm.info.is_delete != 3
              ? _c(
                  "div",
                  { staticClass: "box" },
                  [
                    _c("div", { staticClass: "info" }, [
                      _c(
                        "div",
                        { staticClass: "titlelabel" },
                        [
                          _c("span", {
                            staticClass: "title",
                            domProps: { innerHTML: _vm._s(_vm.info.title) }
                          }),
                          _vm.info.actions &&
                          Object.keys(_vm.info.actions).length
                            ? _c("actions", {
                                attrs: { "action-data": _vm.info.actions },
                                on: { fource: _vm.handlerRepplay }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.info.price
                        ? _c("div", {
                            staticClass: "price",
                            domProps: { innerHTML: _vm._s(_vm.info.price) }
                          })
                        : _vm._e(),
                      _vm.info.tags && _vm.info.tags.length
                        ? _c(
                            "ul",
                            { staticClass: "tags" },
                            _vm._l(_vm.info.tags, function(tag, index) {
                              return _c("li", {
                                key: index,
                                domProps: { innerHTML: _vm._s(tag) }
                              })
                            }),
                            0
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          class: {
                            "time-label":
                              _vm.info.mediaDatas && !_vm.info.mediaDatas.length
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "time el-icon--time",
                            domProps: { innerHTML: _vm._s(_vm.info.time) }
                          }),
                          _vm.type == 4
                            ? _c("i", { staticClass: "info el-icon--group" }, [
                                _vm._v(_vm._s(_vm.info.access))
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm.type == 4
                        ? _c(
                            "div",
                            { staticClass: "groupBtns" },
                            [
                              _vm.hasInGroup
                                ? [
                                    _c("el-button", {
                                      staticClass: "chatButton",
                                      attrs: { icon: "el-icon--chat" },
                                      on: {
                                        click: function($event) {
                                          return _vm.chatWithAuthor(
                                            {
                                              thumb: _vm.info.thumb,
                                              name: _vm.info.title,
                                              id: _vm.info.id
                                            },
                                            "group"
                                          )
                                        }
                                      }
                                    })
                                  ]
                                : _c("el-button", {
                                    staticClass: "addGroupBtn",
                                    attrs: {
                                      icon: "el-icon--addgroup",
                                      loading: _vm.addGroupLoading
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.info.accessPower == "1"
                                          ? _vm.addGroup()
                                          : _vm.addGroupConfirm()
                                      }
                                    }
                                  })
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.type == 4 &&
                      _vm.info.members &&
                      _vm.info.members.length
                        ? _c(
                            "ul",
                            { staticClass: "members" },
                            _vm._l(_vm.info.members, function(user, index) {
                              return _c(
                                "li",
                                {
                                  key: index,
                                  attrs: { type: "success" },
                                  on: {
                                    click: function($event) {
                                      return _vm.routeJump({
                                        name: "personalCenter",
                                        params: { id: user.provider_id }
                                      })
                                    }
                                  }
                                },
                                [
                                  _vm.info.owner === user.provider_id
                                    ? _c("img", {
                                        staticClass: "owner",
                                        attrs: {
                                          src: require("../assets/images/group_owner.png"),
                                          alt: ""
                                        }
                                      })
                                    : _vm._e(),
                                  _c("img", {
                                    staticClass: "thubm",
                                    attrs: {
                                      src: _vm.formartImageUrl(
                                        user.profile_photo
                                      )
                                    }
                                  })
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ]),
                    _vm.type != 4 &&
                    _vm.info.mediaDatas &&
                    _vm.info.mediaDatas.length
                      ? _c(
                          "swiper",
                          {
                            ref: "detailSwiper",
                            staticClass: "swiper",
                            attrs: { options: _vm.swiperOption }
                          },
                          [
                            [
                              _c("div", {
                                staticClass: "swiper-button-prev el-icon--prev",
                                attrs: { slot: "button-prev" },
                                slot: "button-prev"
                              }),
                              _c("div", {
                                staticClass: "swiper-button-next el-icon--next",
                                attrs: { slot: "button-next" },
                                slot: "button-next"
                              })
                            ],
                            _vm._l(_vm.info.mediaDatas, function(item, index) {
                              return _c(
                                "swiper-slide",
                                {
                                  key: index,
                                  attrs: { "is-viewer-image": "true" }
                                },
                                [
                                  item.type === 3
                                    ? _c("img", {
                                        attrs: {
                                          src: _vm.formartImageUrl(item.path)
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.swiperImgClick()
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  item.type === 1 || item.type === 2
                                    ? [
                                        _c("MlVideo", {
                                          style: { height: "100%" },
                                          attrs: { src: item.path }
                                        })
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            })
                          ],
                          2
                        )
                      : _vm._e(),
                    _c("div", {
                      staticClass: "desc",
                      domProps: { innerHTML: _vm._s(_vm.info.desc) }
                    }),
                    _vm.info.files && _vm.info.files.length
                      ? _c(
                          "ul",
                          { staticClass: "files" },
                          _vm._l(_vm.info.files, function(file, index) {
                            return _c("li", { key: index }, [
                              _c("i", {
                                class: _vm.getIconByType(file.name),
                                on: {
                                  click: function($event) {
                                    return _vm.downLoadByUrl(file.path)
                                  }
                                }
                              }),
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.downLoadByUrl(file.path)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(file.name))]
                              )
                            ])
                          }),
                          0
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm.id && _vm.info.is_delete != 3
              ? _c("Comments", {
                  attrs: {
                    id: _vm.id,
                    type: _vm.type === 0 ? 1 : parseInt(_vm.type),
                    fouce: _vm.fouce
                  },
                  on: { commentSuccessful: _vm.commentSuccessful }
                })
              : _vm._e(),
            _vm.info.is_delete == 3
              ? _c("div", { staticClass: "violationTips" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("violationTips")) + "\n      "
                  )
                ])
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "right" },
          [
            _vm.type != 4 && _vm.info.is_delete != 3
              ? _c(
                  "div",
                  { staticClass: "box" },
                  [_c("userinfoCard", { attrs: { userinfo: _vm.userinfo } })],
                  1
                )
              : _vm._e(),
            _vm.recommend.length
              ? _c("recommended", {
                  attrs: { data: _vm.recommend, type: parseInt(_vm.type) },
                  on: { recommendEmit: _vm.handleRecommendEmit }
                })
              : _vm._e(),
            _vm.recommend.length
              ? _c("div", { staticClass: "box recommendPage" }, [
                  _c("i", {
                    staticClass: "el-icon--up",
                    class: { null: _vm.page === 1 },
                    on: {
                      click: function($event) {
                        return _vm.handleRecommendPageChage(false)
                      }
                    }
                  }),
                  _c("i", {
                    staticClass: "el-icon--down",
                    class: { null: _vm.recommend.length < _vm.pageSize },
                    on: {
                      click: function($event) {
                        return _vm.handleRecommendPageChage(true)
                      }
                    }
                  })
                ])
              : _vm._e()
          ],
          1
        )
      ]),
      _vm.showTips
        ? _c("tips", { attrs: { "show-tips": _vm.showTips } })
        : _vm._e(),
      _vm.show
        ? _c("AddTip", {
            attrs: { show: _vm.show, data: _vm.tipData },
            on: { send: _vm.addGroup, close: _vm.addGroupConfirm }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }