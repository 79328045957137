<template>
  <div class="page detail">
    <div class="narrowBox">
      <div class="left">
        <div class="box" v-if="info.is_delete != 3">
          <div class="info">
            <div class="titlelabel">
              <span class="title" v-html="info.title"></span>
              <actions
                :action-data="info.actions"
                v-if="info.actions && Object.keys(info.actions).length"
                @fource="handlerRepplay"
              />
            </div>
            <div class="price" v-if="info.price" v-html="info.price"></div>
            <ul class="tags" v-if="info.tags && info.tags.length">
              <li
                v-for="(tag, index) in info.tags"
                :key="index"
                v-html="tag"
              ></li>
            </ul>
            <div
              :class="{
                'time-label': info.mediaDatas && !info.mediaDatas.length,
              }"
            >
              <i class="time el-icon--time" v-html="info.time"></i>
              <i class="info el-icon--group" v-if="type == 4">{{
                info.access
              }}</i>
            </div>
            <div class="groupBtns" v-if="type == 4">
              <template v-if="hasInGroup">
                <el-button
                  icon="el-icon--chat"
                  class="chatButton"
                  @click="
                    chatWithAuthor(
                      { thumb: info.thumb, name: info.title, id: info.id },
                      'group'
                    )
                  "
                ></el-button>
              </template>

              <el-button
                class="addGroupBtn"
                icon="el-icon--addgroup"
                v-else
                :loading="addGroupLoading"
                @click="
                  info.accessPower == '1' ? addGroup() : addGroupConfirm()
                "
              ></el-button>
            </div>
            <ul
              class="members"
              v-if="type == 4 && info.members && info.members.length"
            >
              <li
                type="success"
                v-for="(user, index) in info.members"
                :key="index"
                @click="
                  routeJump({
                    name: 'personalCenter',
                    params: { id: user.provider_id },
                  })
                "
              >
                <img
                  class="owner"
                  v-if="info.owner === user.provider_id"
                  src="../assets/images/group_owner.png"
                  alt
                />
                <img class="thubm" :src="formartImageUrl(user.profile_photo)" />
              </li>
            </ul>
          </div>
          <swiper
            :options="swiperOption"
            class="swiper"
            v-if="type != 4 && info.mediaDatas && info.mediaDatas.length"
            ref="detailSwiper"
          >
            <!-- v-if="info.mediaDatas && info.mediaDatas.length > 3" -->
            <template>
              <div
                class="swiper-button-prev el-icon--prev"
                slot="button-prev"
              ></div>
              <div
                class="swiper-button-next el-icon--next"
                slot="button-next"
              ></div>
            </template>
            <swiper-slide
              v-for="(item, index) in info.mediaDatas"
              :key="index"
              is-viewer-image="true"
            >
              <img
                v-if="item.type === 3"
                :src="formartImageUrl(item.path)"
                @click="swiperImgClick()"
              />
              <template v-if="item.type === 1 || item.type === 2">
                <MlVideo :src="item.path" :style="{ height: '100%' }"></MlVideo>
              </template>
            </swiper-slide>
          </swiper>
          <div class="desc" v-html="info.desc"></div>
          <ul class="files" v-if="info.files && info.files.length">
            <li v-for="(file, index) in info.files" :key="index">
              <i
                :class="getIconByType(file.name)"
                @click="downLoadByUrl(file.path)"
              ></i>
              <span @click="downLoadByUrl(file.path)">{{ file.name }}</span>
            </li>
          </ul>
        </div>
        <Comments
          v-if="id && info.is_delete != 3"
          :id="id"
          :type="type === 0 ? 1 : parseInt(type)"
          :fouce="fouce"
          @commentSuccessful="commentSuccessful"
        />
        <div class="violationTips" v-if="info.is_delete == 3">
          {{ $t("violationTips") }}
        </div>
      </div>
      <div class="right">
        <div class="box" v-if="type != 4 && info.is_delete != 3">
          <userinfoCard :userinfo="userinfo"></userinfoCard>
        </div>
        <recommended
          v-if="recommend.length"
          :data="recommend"
          :type="parseInt(type)"
          @recommendEmit="handleRecommendEmit"
        ></recommended>
        <div class="box recommendPage" v-if="recommend.length">
          <i
            class="el-icon--up"
            :class="{ null: page === 1 }"
            @click="handleRecommendPageChage(false)"
          ></i>
          <i
            class="el-icon--down"
            :class="{ null: recommend.length < pageSize }"
            @click="handleRecommendPageChage(true)"
          ></i>
        </div>
      </div>
    </div>
    <tips v-if="showTips" :show-tips="showTips"></tips>
    <AddTip
      v-if="show"
      :show="show"
      :data="tipData"
      @send="addGroup"
      @close="addGroupConfirm"
    ></AddTip>
  </div>
</template>
<script>
import actions from "@/components/business/web-site/action";
// import { downloadFile } from "@/utils";
import MlVideo from "@/components/common/me-video";
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import userinfoCard from "@/components/business/web-site/user-info-card";
import recommended from "@/components/business/web-site/recommended";
import { publicDo } from "@/api/dado";
import Comments from "@/components/business/web-site/comments";
import tips from "@/components/business/web-site/dialog/tip";
import AddTip from "@/components/business/web-site/dialog/add";
import { Base64 } from "js-base64";
export default {
  data() {
    return {
      lang: 1,
      id: "",
      type: 0,
      info: {},
      isGroup: false,
      videosrc: "",
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // Any other options that can be got from plugin documentation
      },
      userinfo: {},
      recommend: [],
      page: 1,
      pageSize: 10,
      url: "",
      hasInGroup: false,
      fouce: false,
      showTips: false,
      addGroupLoading: false,
      show: false,
      tipData: {},
      dis: false,
    };
  },
  components: {
    actions,
    swiper,
    swiperSlide,
    MlVideo,
    userinfoCard,
    recommended,
    Comments,
    tips,
    AddTip,
  },
  computed: {
    watchUser() {
      return this.$store.getters.userInfo;
    },
    watchRoute() {
      return this.$route;
    },
    watchInfo() {
      return this.info;
    },
  },
  watch: {
    watchRoute: {
      handler() {
        this.id = this.$Base64.decode(
          this.$route.params.id.replace("pai_", "")
        );
        if (this.$route.params.type) this.type = this.$route.params.type;
        this.loadFuncs();
      },
      dreep: true,
    },
    watchInfo: {
      handler(val) {
        let self = this;
        this.info = val;
        self.getUserInfo(val.owner);
        var isGroupMember = function(member) {
          return member.provider_id === self.$store.getters.userInfo.id;
        };
        if (val.members) self.hasInGroup = val.members.some(isGroupMember);
      },
      dreep: true,
    },
  },
  created() {
    this.$store.dispatch("commitMenuStatus", false);
    this.id = this.$Base64.decode(this.$route.params.id.replace("pai_", ""));
    this.type = this.$route.params.type || 0;
    this.fource = this.$route.params.fource || false;
    this.lang = localStorage.getItem("langCode");
    this.dis = this.$route.params.dis || false;
    this.loadFuncs();
  },
  methods: {
    // 评论成功后回调，刷新页面
    commentSuccessful() {
      location.reload();
    },
    handlerRepplay(val) {
      this.fouce = val;
    },
    addGroupConfirm() {
      let haslogin = this.checkLogin();
      if (haslogin) this.show = !this.show;
      else this.$store.commit("setLoginAndRegisterDialog", true);
    },
    loadFuncs() {
      switch (this.type) {
        case 4:
        case "4":
          this.url = "rest/recommend/group/";
          this.getGroupDetail();
          this.isGroup = true;
          break;
        case 5:
        case "5":
          this.url = "rest/recommend/enterprise/";
          this.getEnterDetail();
          break;
        case 0:
        case "0":
        case "":
          this.url = "rest/recommend/service/";
          this.getServerDetail();
      }
      this.getRecommend();
      // console.log(this.info, "///");
    },
    async handleRecommendEmit(router) {
      await this.jumpPage(router);
    },
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },

    reInit() {
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
    async getUserInfo(id) {
      let params = {
        funcId: "hex_helome_getProfileInfoFunction",
        provider_id: id,
        query_no_cache: 1,
        table_name_replace: 1,
      };
      if (this.$store.getters.userInfo.id)
        params.userId = this.$store.getters.userInfo.id;
      let result = await publicDo(params, "public/data.do").catch();
      if (result) {
        let data = result.data[0];
        this.userinfo = {
          name: data.name,
          thumb: data.profile_photo,
          countryCode: data.country,
          tags: data.skills,
          i_can_do: data.i_can_do,
          id: data.provider_id,
          friend: data.friend_count && data.friend_count !== "0" ? true : false,
        };
      }

      // this.data = this.formartInfo(result.data[0]);
    },
    handleRecommendPageChage(isNext) {
      let page = isNext ? (this.page += 1) : (this.page -= 1);
      this.getRecommend(page);
    },
    async getRecommend(page) {
      let params = {
        language: this.lang,
        pageNum: page || 1,
        pageSize: this.pageSize,
        id: this.id,
      };
      let result = await this.$http({
        url: this.url + this.id,
        params,
        withCredentials: false,
      });
      this.recommend = result.data.data.length
        ? this.formartRecommend(result.data.data)
        : [];
    },
    formartRecommend(data) {
      return data.map((item) => {
        return {
          avatar: item.contentPic,
          id: item.pkId,
          subject: item.subject,
          content: item.content,
        };
      });
    },
    async addGroup(msg) {
      if (this.info.accessPower != "1") {
        this.addGroupConfirm();
      } else if (!this.checkLogin()) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      this.addGroupLoading = !this.addGroupLoading;
      let url = "rest/helogig/v3/joinGroup";
      let result = await this.$http({
        url,
        params: {
          groupId: this.info.id,
          language: localStorage.getItem("langCode"),
          userId: this.$store.getters.userInfo.id,
          type: 0,
          tipContent: msg || "",
        },
        method: "post",
        withCredentials: false,
      }).catch(() => {
        this.addGroupLoading = !this.addGroupLoading;
      });
      this.addGroupLoading = !this.addGroupLoading;
      if (result) {
        this.showTips = !this.showTips;
        setTimeout(() => {
          this.showTips = !this.showTips;
        }, 2000);
        if (this.info.accessPower == "1") this.hasInGroup = !this.hasInGroup;
        // this.$message({
        //   type: "success",
        //   message: result.message || ""
        // });
        // location.reload();
      } else {
        this.$message({
          type: "error",
          message: this.$t("sys_error"),
        });
      }
    },
    async exitGroup() {
      let result = await this.$http({
        url: "rest/helogig/v3/exitGroup/" + this.info.id,
        params: {
          groupId: this.info.id,
          language: localStorage.getItem("langCode"),
          userId: this.$store.getters.userInfo.id,
        },
        method: "post",
        type: "json",
        withCredentials: false,
      }).catch(() => {});
      if (result.code === 200) this.loadFuncs();
      // location.reload();
      // console.log(result, "////result");
    },
    async getServerDetail() {
      let params = {
        language: this.lang,
        id: this.id,
        type: "0", // 服务（1） 需求（2）服务和需求（0）
        userId: this.$store.getters.userInfo.id || "",
      };
      let result = await this.$http({
        url: "rest/detail/v3/getServiceJobDetail",
        params,
        withCredentials: false,
      }).catch(() => {});
      if (result && result.data && result.data.length) {
        this.info = this.formartService(result.data[0]);
      }
    },
    async getGroupDetail() {
      let params = {
        language: this.lang,
        groupId: this.id,
        userId: this.$store.getters.userInfo.id || "",
      };
      let result = await this.$http({
        url: "rest/detail/v3/getGroupDetail",
        params,
        withCredentials: false,
      });
      this.info = this.formartGroup(result.data[0]);
      this.tipData = {
        name: this.info.title,
        thumb: this.info.thumb,
        id: this.info.id,
      };
    },
    async getEnterDetail() {
      let params = {
        language: this.lang,
        enterpriseId: this.id,
        userId: this.$store.getters.userInfo.id || "",
      };
      let result = await this.$http({
        url: "rest/detail/v3/getEnterpriseDetail",
        params,
        withCredentials: false,
      });
      this.info = this.formartEnter(result.data[0]);
    },
    fliterMediadata(arr, getFile) {
      let newArr = [];
      arr.forEach((element) => {
        if (getFile) {
          if (element.type === 4) {
            newArr.push(element);
          }
        } else if (element.type !== 4) {
          newArr.push(element);
        }
      });
      return newArr;
    },
    formartService(data) {
      return {
        title: data.i_can_do,
        price: data.price,
        tags: this.formartTags(data.tags),
        slider: data.profile_photo,
        mediaDatas: this.fliterMediadata(data.mediaDatas, false),
        files: this.fliterMediadata(data.mediaDatas, true),
        desc: data.detail,
        thumb: data.profile_photo,
        id: data.id,
        is_delete: data.is_delete,
        time: data.publish_time,
        owner: data.provider_id,
        actions: {
          pkId: data.id,
          zanNum: data.like_count,
          replyCount: data.reviewer_count,
          likeStatus: data.like_id ? true : false,
          type: 1,
          shareData: {
            title: data.i_can_do,
            thumb: data.profile_photo,
            desc: data.detail.slice(0, 30),
            url: "/detail/" + "pai_" + Base64.encode(data.id),
          },
        },
      };
    },
    formartGroup(data) {
      const title = () => {
        let title = "私密群";
        if (data.access_pattern == 1 && data.group_type == 1) title = "公开群";
        if (data.access_pattern == 2 && data.group_type == 1) title = "会员群";
        if (data.access_pattern == 3 && data.group_type == 1) title = "私密群";
        if (data.access_pattern == 3 && data.group_type == 3) title = "讨论组";
        return title;
      };
      return {
        title: data.group_name,
        tags: this.formartTags(data.group_tag),
        slider: data.group_header,
        mediaDatas: this.fliterMediadata(data.mediaDatas, false),
        files: this.fliterMediadata(data.mediaDatas, true),
        desc: data.group_info,
        thumb: data.group_header,
        id: data.group_id,
        time: data.join_time,
        owner: data.group_owner,
        accessPower: data.access_pattern,
        access: title(),
        members: data.members,
        actions: {
          id: data.group_id,
          zanNum: data.like_count,
          replyCount: data.reviewer_count,
          type: 4,
          likeStatus: data.like_id ? true : false,
          shareData: {
            title: data.group_name,
            thumb: data.group_header,
            desc: data.group_info.slice(0, 30),
            url: "/group/" + "pai_" + Base64.encode(data.group_id),
          },
        },
      };
    },
    formartEnter(data) {
      return {
        title: data.enterprise_name,
        tags: this.formartTags(data.enterprise_tag),
        slider: data.enterprise_photos,
        mediaDatas: this.fliterMediadata(data.mediaDatas, false),
        files: this.fliterMediadata(data.mediaDatas, true),
        desc: data.enterprise_info,
        thumb: data.enterprise_logo,
        id: data.enterprise_id,
        time: data.create_time,
        owner: data.enterprise_owner,
        actions: {
          id: data.enterprise_id,
          zanNum: data.like_count,
          replyCount: data.reviewer_count,
          type: 5,
          likeStatus: data.like_id ? true : false,
          shareData: {
            title: data.enterprise_name,
            thumb: data.enterprise_logo,
            desc: data.enterprise_info.slice(0, 30),
            url:
              "/detail/" +
              "pai_" +
              Base64.encode(data.enterprise_id) +
              "/enterprise",
          },
        },
      };
    },
    swiperImgClick() {
      const viewer = this.$viewer(this.$refs.detailSwiper.$el, {
        filter(image) {
          return image.parentNode.getAttribute("is-viewer-image");
        },
        hidden: function() {
          viewer.destroy();
        },
      });
    },
  },
};
</script>
<style lang="stylus">
.narrowBox {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;

  .left {
    flex: 1;
    // float: left;
    // width: 71.5%;
    overflow: hidden;

    .box {
      background: #ffffff;
      border: 1px solid #ececec;
      padding: 0;
      margin-bottom: 0.9rem;

      .info {
        .time-label {
          border-bottom: 1px solid #eeeeee;
        }

        .titlelabel {
          display: flex;
          flex-flow: row;
          align-items: center;
          padding: 0.9rem;
        }

        .title {
          flex: 1;
          font-size: 24px;
          color: #222222;
          line-height: 38px;
          word-break:break-all;
        }

        .price {
          font-size: 18px;
          padding: 0 0.9rem;
          color: #fe0657;
        }

        .tags {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          padding: 0.5rem 0.9rem;

          // margin-bottom: 0.5rem;
          li {
            color: #666;
            border: 1px solid #3c6;
            border-radius: 50px;
            display: inline-block;
            float: left;
            padding: 0.15rem 0.5rem;
            margin: 0.25rem 0.25rem;
            font-size: 12px;
          }
        }

        .groupBtns {
          margin: 0.5rem 0;
          padding: 0 0.9rem;

          .el-button {
            padding: 0.45rem 0.5rem;
            font-size: 20px;
            width: 120px;
            border-radius: 2rem;
            text-align: center;
            cursor: pointer;
            box-sizing: border-box;
            // border: 2px solid #eeeeee;
            color: #666666;

            &.chatButton {
              background: #33cc66;
              color: #ffffff;
              margin-right: 0.5rem;
              border-color: #33cc66;
            }

            &.delMember {
              color: #999999;
            }

            &.addGroupBtn {
            }
          }
        }

        .members {
          margin: 0.9rem 0 -1px;
          border-bottom: 1px solid #eeeeee;
          padding: 0.5rem 0.9rem;

          li {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 60px;
            border-radius: 60px;
            background: #eeeeee;
            margin: 0 0.3rem 0.3rem;

            .owner {
              width: 22px;
              position: absolute;
              background: rgba(255, 255, 255, 0.6);
              padding: 0.25rem;
              border-radius: 2rem;
              right: 0px;
              bottom: 0px;
              z-index: 99;
            }

            .thubm {
              width: 60px;
              height: 60px;
              border-radius: 60px;
              object-fit: cover;
            }
          }
        }

        .time {
          &:before {
            font-size: 1rem;
            margin: 0 0.25rem;
          }

          font-size: 13px;
          color: #cccccc;
          padding: 0.9rem;
        }

        .info {
          &:before {
            font-size: 18px;
            margin-right: 0.25rem;
          }

          color: #999999;
          font-size: 12px;
        }
      }

      .btns {
        width: 30px;
        background: #eeeeee;

        &.prev {
        }
      }

      .swiper {
        // overflow: hidden;
        padding: 0.5rem;
        border-top: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        background: #f8f8f8;
        max-width: 100%;
        overflow: hidden;
        display: flex;
        flex-flow: row;
        height: 255px;

        .swiper-button-prev, .swiper-button-next {
          background: none;
        }

        .el-icon--next, .el-icon--prev {
          font-size: 1.8rem;
          color: #999;
          line-height: 40px;
          background: #ffffff;
          border-radius: 0.2rem;
          padding: 0.15rem 0;
          opacity: 0.8;

          &.swiper-button-disabled {
            opacity: 0.2;
          }
        }

        .swiper-wrapper {
          .swiper-slide {
            width: 300px;
            height: 255px;
            margin: 0 0.5rem 0 0;
            overflow: hidden;
            position: relative;
            float: left;

            // display: flex;
            // flex-flow: convert;
            // align-items: center;
            // justify-content: center;
            img, video {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            video {
              z-index: 88;
            }

            &:hover {
              i, video, img {
                opacity: 0.8;
                cursor: pointer;
              }
            }

            .el-icon--playVideo {
              font-size: 3.5rem;
              color: #ffffff;
              position: absolute;
              left: 40%;
              top: 40%;
              z-index: 99;
            }
          }
        }

        .el-carousel__item {
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            background: #eeeeee;
          }
        }
      }

      // padding: 0.9rem;
      .desc {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 24px;
        letter-spacing: 0px;
        color: #222222;
        padding: 1.25rem;

        p {
          margin: 0;
          padding: 0;
          line-height: inherit;
        }
      }

      .files {
        border-top: 1px solid #eeeeee;
        padding: 0.9rem;
        display: flex;
        flex-flow: column;

        li {
          font-size: 14px;

          i {
            margin: 0 0.4rem;
            font-size: 2rem;
            color: #33cc66;
            cursor: pointer;
          }

          span {
            cursor: pointer;
          }
        }
      }
    }
    .violationTips{
      width 100%;
      height 100%;
      display flex;
      flex-flow: column;
      align-items center;
      justify-content center;
      font-size: 16px;
      color: #cccccc;
    }
  }

  .right {
    margin-left: 0.9rem;
    width: 350px;
    // float: left;
    display: flex;
    flex-flow: column;

    .box {
      background: #ffffff;
      border: 1px solid #ececec;
      width: 100%;
      padding: 0.5rem;
      margin: 0 0 0.5rem 0;
      display: inline-table;

      &.recommendPage {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;

        i {
          font-size: 25px;
          color: #666666;
          margin: 0 0.5rem;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }

          &.null {
            color: #eeeeee;
            cursor: default;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
</style>
